<script lang="ts" setup>
import { FormKitNode } from '@formkit/core'

const props = defineProps<{
  name?: string
  required: boolean
  label?: string
  htmlLabel?: boolean
  modelValue?: string[] | number[]
  value?: boolean
  validation?: string
  validationMessages?: {
    [key: string]: string
  }

  options?: { value: string | number; label: string }[]
  toggle?: boolean
}>()
defineEmits(['update:modelValue'])

const validationString = computed(() => {
  const validationString = props.validation ?? ''
  const v = validationString.split('|')
  if (props.required) {
    !props.options ? v.push('accepted') : v.push('required')
  }

  return v.filter(Boolean).join('|')
})

const conditionalProps = computed((): any => {
  // FormKit doesn't understand these conditions
  if (props.options?.length) {
    // is multiple
    return {
      value: props.modelValue,
      options: props.options,
    }
  }
  return {
    value: props.value,
  }
})

const wrapperClasses = ['custom-checkbox', props.toggle ? 'toggle' : '']

const node = ref<FormKitNode | undefined>()

const setNode = (n: FormKitNode) => (node.value = n)

watch(
  () => props.modelValue,
  (value) => {
    if (!node.value) return
    if (!props.options?.length) return
    const val = JSON.parse(JSON.stringify(value))
    node.value.input(val)
  },
)
</script>

<template>
  <FormKit
    type="checkbox"
    v-bind="conditionalProps"
    :name="name"
    :label="label ?? ''"
    :validation="validationString ?? ''"
    :validation-messages="validationMessages ?? {}"
    :classes="{
      outer: `outer-checkbox ${$attrs.class ?? ''}`,
      wrapper: wrapperClasses.filter(Boolean).join(' '),
      fieldset: 'checkbox-fieldset',
      options: 'cul',
    }"
    @input="$emit('update:modelValue', $event)"
    @node="setNode"
  >
    <template v-if="htmlLabel" #label>
      <span class="formkit-label html-label" v-html="label" />
    </template>
  </FormKit>
</template>

<style lang="scss" scoped>
.outer-checkbox {
  &[data-invalid='true'] {
    :deep(.formkit-inner) {
      &:before {
        --form-input-border-color: var(--c-red-100) !important;
        --form-input-label-color: var(--c-red-100) !important;
        --form-input-background: var(--c-red-5) !important;
      }
    }
    :deep(.formkit-label) {
      color: var(--c-red-100) !important;
    }
  }
}

:deep(.custom-checkbox) {
  display: flex;
  gap: 16px;

  &[data-checked='true'] {
    --form-input-background: var(--c-blue-100);
    .formkit-inner {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.toggle {
    --form-input-border-color: var(--c-blue-100);
    --toggle-circle-size: 17px;
    --toggle-circle-gap: 4px;

    &[data-checked='true'] {
      .formkit-inner {
        &:before {
          background: var(--form-input-border-color);
          border-color: var(--form-input-border-color);
        }

        &:after {
          background: var(--c-white);
          left: calc(100% - var(--toggle-circle-size) - var(--toggle-circle-gap));
        }
      }
    }
    .formkit-inner {
      --input-width: 45px;
      --input-height: 25px;

      &:before {
        border-radius: 100px;
      }

      &:after {
        background: var(--form-input-border-color);
        border-radius: 50%;

        width: var(--toggle-circle-size);
        height: var(--toggle-circle-size);

        left: var(--toggle-circle-gap);

        transform: translate(0, -50%);

        opacity: 1;
        visibility: visible;

        @include transitions(left);
      }
    }
  }

  .formkit-inner {
    --input-height: 26px;
    --input-width: 26px;
    --input-border: 1px;

    position: relative;
    line-height: 1;

    height: calc(var(--input-height) - var(--input-border) * 2);

    width: 100%;
    min-width: var(--input-width);
    max-width: calc(var(--input-width) - var(--input-border) * 2);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:hover {
      &:before {
        --form-input-border-color: var(--c-blue-100);
        outline-color: var(--form-input-border-color);
        box-shadow: var(--form-input-box-shadow);
      }
    }

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    }

    &:before {
      content: '';

      height: 100%;
      width: 100%;

      border: 1px solid var(--form-input-border-color);
      outline: 0.5px solid var(--outline-color);
      border-radius: var(--form-input-border-radius);
      box-shadow: none;

      background: var(--form-input-background);

      @include transitions(border, outline, background, box-shadow);
    }

    &:after {
      content: '';

      height: calc(var(--input-height) - 2px);
      width: calc(var(--input-width) - 2px);

      background: url('/img/check.svg') no-repeat center center;

      opacity: 0;
      visibility: hidden;

      @include transitions(opacity, visibility);
    }

    input {
      opacity: 0;

      height: 100%;
      width: 100%;

      cursor: pointer;
    }
  }

  .formkit-label {
    color: var(--c-gray-100);

    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    opacity: 1;
    letter-spacing: initial;
    text-transform: none;

    position: static;
    transform: translateY(0);

    &.html-label {
      p {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;

        margin: 0;
      }
    }
  }
}

:deep(.checkbox-fieldset) {
  border: 0;
  padding: 0;
  .formkit-legend {
    margin-bottom: 20px;
  }
}

:deep(.formkit-options) {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
